type TSlide = {
  title: string;
  caption: string;
  alt: string;
};

export const slides: TSlide[] = [
  {
    title: "Система мониторинга дорожного движения",
    caption:
      "Реализован выбор типа перекрестка и визуализация схематического представления перекрестка",
    alt: "",
  },

  {
    title: "Система мониторинга дорожного движения",
    caption:
      "Реализован конструктор формирования перекрестка для отображения его на карте. " +
      "Настроена синхронизация схемы перекрестка с его отображением на карте.",
    alt: "",
  },

  {
    title: "Система мониторинга дорожного движения",
    caption:
      "Реализован выбор направлений движения транспорта, " +
      "пешеходных переходов и стационарных постов учета на схеме перекрестка.",
    alt: "",
  },

  {
    title: "Система мониторинга дорожного движения",
    caption:
      "Создание нового обследуемого участка дороги на карте с возможностью редактирования. " +
      "При редактировании размеченный участок изменяет свой вид, отображаются точки участка, доступные для перемещения.",
    alt: "",
  },

  {
    title: "Система мониторинга дорожного движения",
    caption:
      "Возможность отображения и выбора на карте созданных ранее обследуемых участков дороги.",
    alt: "",
  },

  {
    title: "Система мониторинга дорожного движения",
    caption:
      "Загрузка трека маршрута движения в формате GPX или CSV с последующим отображением его на карте. " +
      "Реализована возможность формирования и редактирования обследуемых участков дороги на маршруте.",
    alt: "",
  },

  {
    title: "Система мониторинга дорожного движения",
    caption:
      "Реализована карта с возможностью перехода по уровням территориального деления. " +
      "Тепловая карта представлена для визуализации статистической информации.",
    alt: "",
  },

  {
    title: "Система мониторинга дорожного движения",
    caption: "Реализован поиск по фильтру с центрированием на искомом объекте.",
    alt: "",
  },

  {
    title: "Система мониторинга показателей и индикаторов",
    caption:
      "Реализована тепловая карта с возможностью просмотра и сравнения показателей по нескольким регионам, " +
      "отображения отклонений показателей от среднего значения по стране.",
    alt: "",
  },

  {
    title: "Система мониторинга показателей и индикаторов",
    caption: "Реализована карта с возможностью поиска субъекта РФ и просмотра его показателей.",
    alt: "",
  },

  {
    title: "Система мониторинга показателей и индикаторов",
    caption:
      "Реализована возможность визуального поиска необходимого типа объекта через группировку объектов.",
    alt: "",
  },

  {
    title: "Система мониторинга показателей и индикаторов",
    caption:
      "Реализована возможность просмотра значений нескольких показателей с выбранными разрезами, " +
      "настройка вида графического представления.",
    alt: "",
  },

  {
    title: "Система мониторинга показателей и индикаторов",
    caption:
      "Реализована возможность сравнения выбранного показателя со значениями аналогичных периодов.",
    alt: "",
  },

  {
    title: "Система мониторинга показателей и индикаторов",
    caption:
      "Настроен просмотр преднастроенных визуальных представлений показателей, группировка виджетов по папкам",
    alt: "",
  },

  {
    title: "Система ведения нормативно-справочной информации",
    caption:
      "Реализована возможность создания и настройки витрин данных для взаимодействия со смежными подсистемами.",
    alt: "",
  },

  {
    title: "Система ведения нормативно-справочной информации",
    caption: "Реализована проверка качества данных объекта НСИ.",
    alt: "",
  },

  {
    title: "Система ведения нормативно-справочной информации",
    caption: "Реализована проверка новых элементов объекта НСИ.",
    alt: "",
  },

  {
    title: "Система ведения нормативно-справочной информации",
    caption:
      "Созданы инструменты создания и редактирования объектов НСИ " +
      "с возможностью проверки уникальности атрибутов через объединение их в кортежи.",
    alt: "",
  },

  {
    title: "Система ведения нормативно-справочной информации",
    caption:
      "Созданы инструменты создания и редактирования объектов НСИ " +
      "с возможностью проверки уникальности атрибутов через объединение их в кортежи",
    alt: "",
  },

  {
    title: "Система ведения нормативно-справочной информации",
    caption: "Реализован интерфейс для визуального сравнения различных версий объектов НСИ.",
    alt: "",
  },

  {
    title: "Интенсивность транспортных потоков",
    caption:
      "Реализована возможность представления в картографических виджетах " +
      "данных состава движения по участкам дорог.",
    alt: "",
  },

  {
    title: "Интенсивность транспортных потоков",
    caption:
      "Реализована возможность отображения расчетных данных интенсивности движения на основе треков.",
    alt: "",
  },

  {
    title: "Интенсивность транспортных потоков",
    caption: "Реализованы настройки вариантов представлений данных для их сравнения.",
    alt: "",
  },
];
