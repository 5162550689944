






































import { Component, Vue } from "vue-property-decorator";
import Flickity from "vue-flickity/src/flickity.vue";
import { slides } from "@/data/completed-projects";
import { SMRoot } from "@/store";

@Component({
  components: {
    Flickity,
  },
})
export default class CompletedProjects extends Vue {
  $refs!: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    thumbs: any;
  };

  protected slides = slides;

  protected get reorganizedSlides() {
    const name = (n: number) => `${n > 9 ? "" : "0"}${n}`;
    const src = (n: number, extension: "gif" | "png") =>
      require("@/assets/carousel/" + name(n) + "." + extension);
    const image = (n: number, alt: string) => ({
      gif: {
        src: src(n, "gif"),
        alt,
      },
      png: {
        src: src(n, "png"),
        alt,
      },
    });
    return this.slides.map(({ title, caption, alt }, i) => ({
      key: "slide:" + i,
      title,
      caption,
      image: image(i + 1, alt),
      action: () => {
        this.$refs.thumbs.select(i);
      },
    }));
  }

  protected currentSlideIndex = 0;

  protected get currentSlide() {
    return this.reorganizedSlides[this.currentSlideIndex];
  }

  protected setCurrentSlideIndex(index: number) {
    this.currentSlideIndex = index;
  }

  protected get device() {
    return SMRoot.device;
  }

  protected get flickityOptions() {
    return {
      draggable: this.device !== "desktop",
      wrapAround: true,
      pageDots: false,
      on: {
        change: (index: number) => {
          this.currentSlideIndex = index;
        },
      },
    };
  }
}
