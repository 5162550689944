var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"bg"}),_c('div',{staticClass:"content"},[_c('h2',[_vm._v("Реализованные проекты")]),_c('div',{staticClass:"carousel"},[_c('div',{staticClass:"carousel--current-slide sm-b"},[_c('figure',[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('h3',{key:_vm.currentSlide.title,domProps:{"textContent":_vm._s(_vm.currentSlide.title)}})]),_c('flickity',{attrs:{"options":_vm.flickityOptions}},_vm._l((_vm.reorganizedSlides),function(ref){
var key = ref.key;
var gif = ref.image.gif;
return _c('div',{key:key,staticClass:"carousel-cell img-container"},[_c('img',_vm._b({attrs:{"loading":"lazy"}},'img',gif,false))])}),0),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('sub',{key:_vm.currentSlide.caption,domProps:{"textContent":_vm._s(_vm.currentSlide.caption)}})])],1)]),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('div',{key:_vm.currentSlideIndex,staticClass:"carousel--current-slide sm-a"},[_c('figure',[_c('div',{staticClass:"img-container"},[_c('img',_vm._b({attrs:{"loading":"lazy"}},'img',_vm.currentSlide.image.gif,false))]),_c('figcaption',[_c('h3',{domProps:{"textContent":_vm._s(_vm.currentSlide.title)}}),_c('sub',{domProps:{"textContent":_vm._s(_vm.currentSlide.caption)}})])])])]),_c('div',{staticClass:"carousel--thumbs sm-a"},[_c('flickity',{ref:"thumbs",attrs:{"options":_vm.flickityOptions},on:{"change":_vm.setCurrentSlideIndex}},_vm._l((_vm.reorganizedSlides),function(ref){
var key = ref.key;
var png = ref.image.png;
var action = ref.action;
return _c('div',{key:key,staticClass:"carousel-cell",on:{"click":action}},[_c('img',_vm._b({attrs:{"loading":"lazy"}},'img',png,false))])}),0)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }